import { initForms } from './components/initForms';
import { initStickyTableHeader } from './components/initStickyTableHeader';
import { initAccordion } from './components/accordion';
import { initJivosite } from './components/initJivosite';
import { InitAnimationSearch } from './components/InitAnimationSearch';
import { initOpenCreateAccountModal, initOpenCreateAccountModalFull, initSearch } from './components/modal/initModal';
import { Modal } from './components/modal/Modal';
import { initPhoneMask } from './components/initPhoneMask';
import { initLangSwitcher } from './components/initLangSwitcher';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initMobileFeatureMenu } from './components/initMobileFeatureMenu';
import { initRenderPricesPage } from './components/initRenderPricesPage';
import { initMobileMenu } from './components/initMobileMenu';
import { initSliders } from './components/initSliders';
import { initScrollLocker } from './assets/scrollLocker';
import { initStickyBanner } from './components/initStickyBanner';
import { oldScriptPrisePage } from './components/oldScriptPrisePage';
import { initBannerTimer } from './components/initBannerTimer';
import { initAutocompleteDevices } from './components/initAutocompleteDevices';
import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { initModalCreateAccountEmailFull } from './components/forms/initModalCreateAccountEmailFull';
import { initDeclineModal } from './components/modal/initDeclineModal';

document.addEventListener('DOMContentLoaded', function () {
    initLazyLoadFonts();
    initScrollLocker();
    const animationSearch = new InitAnimationSearch();
    const modal = new Modal();
    initForms(modal);
    initStickyTableHeader();
    initAccordion();
    initSliders();
    initJivosite();
    initOpenCreateAccountModal(modal);
    initSearch(modal, animationSearch);
    initPhoneMask(modal, animationSearch);
    initLangSwitcher();
    initMobileMenu();
    initScrollToAnchor();
    initMobileFeatureMenu();
    initRenderPricesPage();
    initBannerTimer();
    initStickyBanner();
    initAutocompleteDevices();
    initOpenCreateAccountModalFull(modal);
    initDeclineModal();

    // old page price, need delete with price.html.twig, prices_a.html.twig
    // oldScriptPrisePage();
});
