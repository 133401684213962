import { isValidMail } from './halper-functions/isValidMail';

export const initContactUsForm = () => {
    // VARS
    const contactUsForm = document.querySelector('[data-contact-us-form]');
    if (!contactUsForm) return;
    const userName = contactUsForm.querySelector('[data-input-name]');
    const userEmail = contactUsForm.querySelector('[data-input-email]');
    const userMessage = contactUsForm.querySelector('[data-input-message]');
    const submit = contactUsForm.querySelector('[data-submit]');
    const successfulSendModal = document.querySelector('[data-successful-send-modal]');
    const fieldsSelector = {
        userName: '[data-input-name]',
        userEmail: '[data-input-email]',
        userMessage: '[data-input-message]',
        recaptcha: '[data-recaptcha]',
    };
    const classShowErrorMessage = 'is-visible-error';
    let isValidForm = true;

    // LISTENERS
    submit.addEventListener('click', handleOnSubmit, false);

    userName.addEventListener('focus', handleOnFocusFieldUserName, false);

    userEmail.addEventListener('focus', handleOnFocusFieldUserEmail, false);

    userMessage.addEventListener('focus', handleOnFocusFieldUserMessage, false);

    // HANDLERS
    function handleOnSubmit(event) {
        validateUserName();
        validateUserEmail();
        validateUserMessage();
        validateReCaptcha();

        if (isValidForm) {
            showSuccessfulSendModal();
        } else {
            event.preventDefault();
            isValidForm = true;
        }
    }

    function handleOnFocusFieldUserName() {
        hideErrorMessage(fieldsSelector.userName);
    }

    function handleOnFocusFieldUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);
    }

    function handleOnFocusFieldUserMessage() {
        hideErrorMessage(fieldsSelector.userMessage);
    }

    // FUNCTIONS
    function validateUserName() {
        hideErrorMessage(fieldsSelector.userName);

        if (userName.value.trim()) {
            hideErrorMessage(fieldsSelector.userName);
        } else {
            showErrorMessage(fieldsSelector.userName, 'required');
            isValidForm = false;
        }
    }

    function validateUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);

        if (!userEmail.value.trim()) {
            showErrorMessage(fieldsSelector.userEmail, 'required');
            isValidForm = false;
        } else if (!isValidMail(userEmail.value.trim())) {
            showErrorMessage(fieldsSelector.userEmail, 'invalid');
            isValidForm = false;
        } else {
            hideErrorMessage(fieldsSelector.userEmail);
        }
    }

    function validateUserMessage() {
        hideErrorMessage(fieldsSelector.userMessage);

        if (userMessage.value.trim()) {
            hideErrorMessage(fieldsSelector.userMessage);
        } else {
            showErrorMessage(fieldsSelector.userMessage, 'required');
            isValidForm = false;
        }
    }

    function validateReCaptcha() {
        hideErrorMessage(fieldsSelector.recaptcha);

        if (isValidReCaptcha()) {
            hideErrorMessage(fieldsSelector.recaptcha);
        } else {
            showErrorMessage(fieldsSelector.recaptcha, 'captcha');
            isValidForm = false;

            setTimeout(() => {
                hideErrorMessage(fieldsSelector.recaptcha);
            }, 5000);
        }
    }

    function hideErrorMessage(selector) {
        const field = document.querySelector(selector);
        const errors = field.closest('[data-input-wrapper]').querySelectorAll('[data-field-error]');

        field.classList.remove(classShowErrorMessage);
        [...errors].forEach((error) => {
            error.classList.remove(classShowErrorMessage);
        });
    }

    function showErrorMessage(selector, type) {
        const field = document.querySelector(selector);
        const error = field.closest('[data-input-wrapper]').querySelector(`[data-field-error="${type}"]`);

        field.classList.add(classShowErrorMessage);
        error.classList.add(classShowErrorMessage);
    }

    function isValidReCaptcha() {
        return Boolean(grecaptcha.getResponse());
    }

    function showSuccessfulSendModal() {
        successfulSendModal.classList.remove('hidden');
    }
};
