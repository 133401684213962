import { scrollToElem } from '../helper-function/scrollToElem';

export const initScrollToAnchor = () => {
    // VARS
    const anchorLinks = document.querySelectorAll('[data-anchor-link]');
    const homePage = document.querySelector('[data-home]');
    const offset = window.innerWidth > 1280 ? -100 : -140;

    // LISTENERS
    anchorLinks.forEach((link) => {
        link.addEventListener('click', function (e) {
            if (homePage) {
                e.preventDefault();
            }

            const href = this.getAttribute('href');
            const selector = href.slice(href.indexOf('#'), href.length);
            scrollToElem(selector, offset);
        });
    });
};
