import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js';

export const initPhoneMask = (modal, animationSearch) => {
    // VARS
    if (!document.querySelector('[data-catch-number]') && !document.querySelector('[data-catch-number-full]')) return;
    const locationForms = document.querySelectorAll('[data-phone-number-form]');
    const telInputs = document.querySelectorAll('[data-tell-input]');
    const popupPhoneNumber = document.querySelector('[data-popup-phone-number]');
    const modalSuccessBlock = document.querySelector('[data-modal-success-block]');
    const phoneNumberInputValue = document.querySelector('[data-phone-number-input-value]');
    const userCountryCode = document.querySelector('[data-user-country]').dataset.userCountry.trim();
    const maxLength = 10;
    if (!userCountryCode) return;

    // LISTENERS
    locationForms.forEach((form, index) => {
        const iti = intlTelInput(telInputs[index], {
            separateDialCode: true,
            initialCountry: userCountryCode,
        });

        telInputs[index].addEventListener('countrychange', function () {
            locationForms[index].classList.remove('error');
            this.value = '';
            this.blur();
        });

        telInputs[index].addEventListener('input', function () {
            handleOnInputPhoneNumber(locationForms[index], this);
        });

        telInputs[index].addEventListener('paste', handleOnPastePhoneNumber, false);

        form.addEventListener('submit', function (event) {
            event.preventDefault();

            phoneNumberInputValue.value = iti.getNumber().replace(/[^0-9]/g, '');
            let phoneNumber = iti.getNumber();

            if (phoneNumber.trim().length) {
                form.classList.remove('error');
                popupPhoneNumber.textContent = phoneNumber.trim();
                animationSearch.productRenderLoop();
                modal.open('valid-phone');
                setTimeout(() => {
                    modalSuccessBlock.classList.add('success');
                }, 7000);
            } else {
                form.classList.add('error');
            }
        });
    });

    // HANDLERS
    function handleOnInputPhoneNumber(locationForm, input) {
        locationForm.classList.remove('error');
        replacePhoneNumberOnInput(input);
    }

    function handleOnPastePhoneNumber() {
        replacePhoneNumberOnPaste(this);
    }

    // FUNCTIONS
    function replacePhoneNumberOnInput(input) {
        input.value = input.value.replace(/[^0-9]/, '');

        if (input.value.trim().length > maxLength) {
            input.value = input.value.trim().substring(0, input.value.length - 1);
        }
    }

    function replacePhoneNumberOnPaste(input) {
        setTimeout(() => {
            input.value = input.value.replaceAll(/[^0-9]/g, '');

            if (input.value.trim().length > maxLength) {
                input.value = input.value.trim().substring(0, input.value.trim().length - maxLength);
            }
        }, 0);
    }
};
