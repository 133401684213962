export const initOpenCreateAccountModal = (modal) => {
    if (!document.querySelector('[data-modal-wrap]')) return;
    if (!document.querySelector('[data-catch-number]')) return;
    const btnOpenCreateAccount = document.querySelector('[data-create-account]');
    const agreeCheckbox = document.querySelector('[data-modal-processing-checkbox]');

    if (!btnOpenCreateAccount) return;

    btnOpenCreateAccount.addEventListener('click', () => {
        modal.open('create-account');

        agreeCheckbox.checked && agreeCheckbox.click();
    });
};

export const initOpenCreateAccountModalFull = (modal) => {
    if (!document.querySelector('[data-modal-wrap]')) return;
    if (!document.querySelector('[data-catch-number-full]')) return;
    const btnOpenCreateAccount = document.querySelector('[data-create-account]');

    if (!btnOpenCreateAccount) return;

    btnOpenCreateAccount.addEventListener('click', () => {
        modal.open('create-account');
    });
};

export const initSearch = (modal, animationSearch) => {
    if (!document.querySelector('[data-modal-wrap]')) return;
    const btnSearches = document.querySelectorAll('[data-search]');

    btnSearches.forEach((btnSearch) => {
        btnSearch.addEventListener('click', () => {
            modal.open('valid-phone');
            animationSearch.productRenderLoop();
            setTimeout(() => {
                document.querySelector('[data-modal-success-block]').classList.add('success');
            }, 7000);
        });
    });
};
