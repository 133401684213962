import { initCreateAccountEmail } from './forms/initCreateAccountEmail';
import { initContactUsForm } from './forms/initContactUsForm';
import { initModalCreateAccountEmail } from './forms/initModalCreateAccountEmail';
import { initModalCreateAccountEmailFull } from './forms/initModalCreateAccountEmailFull';

export const initForms = (modal) => {
    initCreateAccountEmail();
    initContactUsForm();
    initModalCreateAccountEmail(modal);
    initModalCreateAccountEmailFull(modal);
};
