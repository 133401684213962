import Glide from '@glidejs/glide';

export const initFeatureSlider = () => {
    const sliderSelector = '[data-init-feature-slider]';
    const sliderBox = document.querySelector(`${sliderSelector}`);
    if (!sliderBox) return;

    const featureSlider = new Glide(`${sliderSelector}`, {
        type: 'carousel',
        startAt: 1,
        perView: 6,
        focusAt: 0,
        autoplay: 3000,
        gap: 30,
        breakpoints: {
            1280: {
                perView: 4,
            },
            960: {
                perView: 3,
                gap: 16,
            },
            600: {
                perView: 1.2,
                gap: 16,
            },
        },
    });

    featureSlider.mount();
};
