import autoComplete from '@tarekraafat/autocomplete.js';

export const initAutocompleteDevices = () => {
    const input = document.querySelector('[data-autocomplete-devices]');
    if (!input) return;

    new autoComplete({
        data: {
            src: async () => {
                const source = await fetch('/compatibility.json');
                return await source.json();
            },
            key: ['searchString'],
            cache: true,
        },
        sort: (a, b) => {
            if (a.match < b.match) return -1;
            if (a.match > b.match) return 1;
            return 0;
        },
        selector: '[data-autocomplete-devices]',
        threshold: 0,
        debounce: 0,
        searchEngine: 'loose',
        highlight: true,
        maxResults: 20,
        resultsList: {
            render: true,
            container: (source) => {
                source.setAttribute('id', 'autoComplete-list');
            },
            destination: '[data-compatibility-result]',
            position: 'afterend',
            element: 'ul',
        },
        resultItem: {
            content: (data, source) => {
                source.innerHTML = data.match;
                document.querySelector('.selection-no-result').classList.add('hidden');
                document.querySelector('.selection-no-result-i').classList.add('hidden');
            },
            element: 'li',
        },
        noResults: () => {
            document.getElementById('autoComplete-check').addEventListener('click', () => {
                document.querySelector('.selection-finded').classList.add('hidden');
                document.querySelector('.selection-finded-i').classList.add('hidden');
                document.querySelector('.selection-no-result').classList.remove('hidden');
                document.querySelector('.selection-no-result-i').classList.remove('hidden');
                document.querySelector('.selection-finded').classList.add('hidden');
                document.querySelector('.selection-finded-i').classList.add('hidden');
                document.querySelector('.selection-no-result').classList.remove('hidden');
                document.querySelector('.selection-no-result-i').classList.remove('hidden');
                document.querySelector('[data-autocomplete-devices]').setAttribute('placeholder', 'Enter phone model');
            });
        },
        onSelection: (feedback) => {
            const selection = feedback.selection.value.searchString;
            document.querySelector('.selection-finded').classList.remove('hidden');
            document.querySelector('.selection-finded-i').classList.remove('hidden');
            document.querySelector('[data-autocomplete-devices]').value = '';
            document.querySelector('[data-autocomplete-devices]').setAttribute('placeholder', selection);
        },
    });
};
